import { useEffect } from 'react'
import { usePriceCakeBusd } from 'state/hooks'

const useGetDocumentTitlePrice = () => {
  const cakePriceUsd = usePriceCakeBusd()
  useEffect(() => {
    console.log("title")
  })
}
export default useGetDocumentTitlePrice

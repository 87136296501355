import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'NCAKE',
    lpAddresses: {
      97: '',
      56: '0xf7c551ac6bbf3a6edc018bc2bbd972fa2c583d6e',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0xf7c551ac6bbf3a6edc018bc2bbd972fa2c583d6e',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'NCAKE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x249a2628770459C1e1adb7F2B2896E3036A7F68a',   // lp address token-bnb
    },
    tokenSymbol: 'NCAKE',
    tokenAddresses: {
      97: '',
      56: '0xf7c551ac6bbf3a6edc018bc2bbd972fa2c583d6e', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms

import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'NCAKE',
    tokenAddress: '0xf7c551ac6bbf3a6edc018bc2bbd972fa2c583d6e',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xf7c551ac6bbf3a6edc018bc2bbd972fa2c583d6e',  // token address
    contractAddress: {
      97: '',
      56: '0xfB96aC7546C0b8c2F132C3C8C6eFee8E7cF6EA18',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
